import React, { useCallback, useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { connect } from 'react-redux'
import { toggleFilter } from "../../state/filter"
import { getUser } from "../../services/auth"
import RenderHomeType from "../../components/Filter/RenderHomeType"
import { doFilter } from "../../services/filter"

const SidebarCategory = () => {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const query = useStaticQuery(graphql`
    {
        silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
            UndigitalGlobalConfig {
                hidePricingGlobally
                hideStyleSection
                hideHomeDesignSection
                hideFacadesSection    
                hideFinishesFixturesSection
                hideCabinetrySection
                hideFurnitureSection
            }
        }
    }
`)
    const globalConfig = query.silverStripeDataObject.UndigitalGlobalConfig
    var categories = [
      { name: "All", URLSegment: "/explore/", linkClass: "active" }
    ]
    if(globalConfig.hideStyleSection === 0){
        categories.push({ name: "Style", URLSegment: "/explore/style/", linkClass: "link" })
    }
    if(globalConfig.hideHomeDesignSection === 0){
        categories.push({ name: "Home Designs", URLSegment: "/explore/home-designs/", linkClass: "link" })
    }
    if(globalConfig.hideFacadesSection === 0){
        categories.push({ name: "Facades", URLSegment: "/explore/facades/", linkClass: "link" })
    }
    if(globalConfig.hideFinishesFixturesSection === 0){        
        categories.push({ name: "Fixtures & Finishes", URLSegment: "/explore/finishes-fixtures/", linkClass: "link" })
    }
    if(globalConfig.hideCabinetrySection === 0){
        categories.push({ name: "Cabinetry", URLSegment: "/explore/cabinetry/", linkClass: "link" })
    }
    if(globalConfig.hideFurnitureSection === 0){
        categories.push({ name: "Furniture", URLSegment: "/explore/furniture/", linkClass: "link" })
    }
  const categoryNav = useMemo(() => {
    return (
      <ul className="category-nav">
        {categories.map((item, idx) => (
          <li key={`category-${idx}`}>
            <a href={`${item.URLSegment}`} className={item.linkClass}>{item.name}</a>
          </li>
        ))}
      </ul>
    )
  })

  return (
    <>
      <div className="sidebar-category">
        {categoryNav}
      </div>
    </>
  )
}


export default SidebarCategory